import React from "react";
import ReactDOM from "react-dom/client";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.js";
import { BrowserRouter } from "react-router-dom";
import "aos/dist/aos.css";
import "../src/style/icons/fontawesome/css/all.min.css";
import "../src/style/icons/fontawesome/css/fontawesome.min.css";
import { Provider } from "react-redux";
import store from "./core/data/redux/store";
import "../src/style/icons/feather/css/iconfont.css";
import "../src/style/scss/main.scss";
import "./../src/index.css";
import "../src/style/icons/boxicons/css/boxicons.min.css";
import Feature from "./Feature";

import i18n from "i18next";
import { I18nextProvider } from "react-i18next";
import { ro } from "./locales/ro";
import { us } from "./locales/us";

i18n.init({
  debug: true,
  lng: localStorage.getItem("i18nextLng") || "ro",
  fallbackLng: "gb",
  interpolation: {
    escapeValue: false // not needed for react as it escapes by default
  },
  resources: {
    gb: us,
    ro: ro
  }
});

const rootElement = document.getElementById("root");
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <I18nextProvider i18n={i18n}>
            <Feature />
          </I18nextProvider>
        </BrowserRouter>
      </Provider>
    </React.StrictMode>
  );
} else {
  console.error("Element with id 'root' not found.");
}
