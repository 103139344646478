import React from "react";

import { Outlet, Route, Routes } from "react-router-dom";
import Error404 from "../pages/errorpages/error404";
import TermsCondition from "../pages/termscondition/termscondition";
import PrivacyPolicy from "../pages/privacypolicy/privacypolicy";
import TourPage from "../TourPage/TourPage";
import Checkout from "../checkout/Checkout";
import CheckoutSuccess from "../checkout/CheckoutSuccess";
import CheckoutFail from "../checkout/CheckoutFail";
// import { Training } from "../pages/Training";
// import { Rental } from "../pages/Rental";
// import { LeaveNoTrace } from "../pages/LeaveNoTrace";
import Footer from "../Common/Footer";
import Contact from "../Contact/Contact";
import AboutUs from "../pages/AbousUs";
import { Faq } from "../../components/Faq";
import EmailVerified from "../../components/Auth/EmailVerified";
import { MemberBenefits } from "../pages/MemberBenefits";
import TravelCompanionView from "../pages/TravelCompanionView";
import Header from "../Common/Header";
import Home from "../Home/Home";
import { OurTours } from "../pages/OurTours";
import BuyVoucher from "../pages/BuyVoucher";
import { useTranslation } from "react-i18next";

const AllRoutes = () => {
  const { i18n } = useTranslation();

  function PageLayout() {
    React.useEffect(() => {
      const url = new URL(window.location.href);
      if (
        url.searchParams.has("lang") &&
        url.searchParams.get("lang") !== localStorage.getItem("i18nextLng")
      ) {
        localStorage.setItem(
          "i18nextLng",
          url.searchParams.get("lang") || "ro"
        );
        i18n.changeLanguage(url.searchParams.get("lang") || "ro");

        return;
      }

      const lng = localStorage.getItem("i18nextLng");
      if (!lng) {
        localStorage.setItem("i18nextLng", "ro");
      } else if (lng !== "ro") {
        url.searchParams.set("lang", lng);

        window.history.replaceState({}, "", url.toString());
      }
    }, []);
    return (
      <>
        <Header />
        <Outlet />
        <Footer />
        <a
          href="https://wa.me/40753399339"
          className="float-whapp"
          target="_blank"
          rel="noreferrer"
          aria-label="Whatsapp"
        >
          <i className="fab fa-whatsapp my-float"></i>
        </a>
      </>
    );
  }

  return (
    <>
      <Routes>
        <Route caseSensitive path={"/"} element={<PageLayout />}>
          <Route caseSensitive path={"/"} element={<Home />} />
          <Route caseSensitive path={"/despre-noi"} element={<AboutUs />} />

          <Route caseSensitive path="/tur/:tourId" element={<TourPage />} />
          <Route
            caseSensitive
            path="/tur/:tourId/:date"
            element={<TourPage />}
          />

          <Route
            caseSensitive
            path="/training/:tourId"
            element={<TourPage />}
          />
          <Route
            caseSensitive
            path="/training/:tourId/:date"
            element={<TourPage />}
          />

          <Route
            caseSensitive
            path="/checkout/:tourId/:date/:packageName"
            element={<Checkout />}
          />

          <Route
            caseSensitive
            path={"/checkout-success"}
            element={<CheckoutSuccess />}
          />
          <Route
            caseSensitive
            path={"/email-verified"}
            element={<EmailVerified />}
          />
          <Route
            caseSensitive
            path={"/beneficii-membri"}
            element={<MemberBenefits />}
          />
          <Route
            caseSensitive
            path={"/checkout-fail"}
            element={<CheckoutFail />}
          />
          {/* <Route
            caseSensitive
            path="/leave-no-trace"
            element={<LeaveNoTrace />}
          /> */}
          <Route
            caseSensitive
            path="/experienta-off-road-voucher-cadou"
            element={<BuyVoucher />}
          />
          <Route caseSensitive path="/faq" element={<Faq />} />
          <Route
            caseSensitive
            path="/partener-de-calatorie"
            element={<TravelCompanionView />}
          />
          <Route
            caseSensitive
            path="/tururile-noastre"
            element={<OurTours />}
          />

          <Route caseSensitive path="/contact" element={<Contact />} />

          <Route
            caseSensitive
            path={"/termeni-si-conditii"}
            element={<TermsCondition />}
          />
          <Route
            caseSensitive
            path={"/politica-de-confidentialitate"}
            element={<PrivacyPolicy />}
          />
          <Route caseSensitive path={"*"} element={<Error404 />} />
        </Route>
      </Routes>
    </>
  );
};
export default AllRoutes;
