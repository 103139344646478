import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Breadcrumbs } from "../../components/Breadcrumbs";

export function MemberBenefits() {
  const { t } = useTranslation();
  const benefits = t("memberBenefits.benefits", {
    returnObjects: true
  }) as string[];
  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href={`https://firstgearclub.com/beneficii-membri`}
        />

        <link
          rel="alternate"
          hrefLang="ro"
          href={`https://firstgearclub.com/beneficii-membri`}
        />
        <link
          rel="alternate"
          hrefLang="en"
          href={`https://firstgearclub.com/beneficii-membri?lang=en`}
        />
      </Helmet>
      <Breadcrumbs
        items={[
          {
            link: `#`,
            name: `${t("memberBenefits.title")}`
          }
        ]}
        title={`${t("memberBenefits.title")}`}
      />
      <div className="main-wrapper">
        {/* Detail Page Head*/}
        <section className="product-detail-head">
          <div className="container">
            <div className="detail-page-head">
              <div className="detail-headings">
                <div className="camaro-info">
                  <h3 className="mb-0 primaryFont">
                    {t("memberBenefits.title")}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* /Detail Page Head*/}

        <section className="section product-details pt-2">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <>
                  {/* Car Features */}
                  <div className="review-sec listing-feature">
                    <div className="review-header">
                      <h4>{t("memberBenefits.subtitle")}</h4>
                    </div>
                    <div className="listing-description ps-3">
                      <div className="row">
                        {benefits.map((item, index) => (
                          <div className="col-md-5 p-0" key={index}>
                            <ul>
                              <li>
                                <span>
                                  <i className="bx bx-check-double" />
                                </span>
                                {item}
                              </li>
                            </ul>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  {/* /Car Features */}

                  {/* Video */}
                  <div className="review-sec mb-0">
                    <div className="review-header">
                      <h4>{t("memberBenefits.videoLabel")}</h4>
                    </div>
                    <div className="short-video">
                      <iframe
                        src="https://www.youtube.com/embed/JcsIfI3PUR0"
                        width={100}
                        height={350}
                      />
                    </div>
                  </div>
                  {/* /Video */}
                </>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
